
















































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import MintViewModel from '../viewmodels/mint-viewmodel'

@Observer
@Component({
  components: {},
})
export default class SummonDialog extends Vue {
  @Inject() vm!: MintViewModel
  closeDialog() {
    this.vm.setOpenSummonDialog(false);
    this.vm.resetNFTMintAmount();
  }
}
